.landing-page__r2 .bx--tabs--scrollable {
  transform: translateZ(0);
  justify-content: flex-end;
}

.landing-page__r2 .bx--tabs__nav {
  right: 0;
}

.landing-page__r2 .bx--tab-content {
  padding: 0;
}