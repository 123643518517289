@import 'carbon-components/scss/globals/scss/vendor/@carbon/type/scss/font-family.scss';
@import 'carbon-components/scss/globals/scss/vendor/@carbon/layout/scss/breakpoint.scss';
@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/vars.scss';

@import './components/AppHeader/header.scss';
@import './components/Info/info.scss';

@import './modules/auth/views/Login/login.scss';
@import './modules/main/views/Home/home.scss';


// @import './content/LandingPage/landing-page.scss';
// @import './content/RepoPage/repo-page.scss';
// @import './components/Info/info.scss';

form .btn-block {
    max-width: 100%;
    width: 100%;
    display: block;
    text-align: center;
}